import axios from "./AxiosInterceptors";
import store from "../store";
import Toast from "../components/Notifications/Toast";

const ROOT_URL = `https://certificationapi-dev.financialdataexchange.org/certification-portal`;

export default {
  ROOT_URL,
  ssoAuthentication: function () {
    window.location.href = ROOT_URL + "/oauth2/authorization";
  },
  ssoSignout: function (token, req) {
    store.commit("setTokenStartTime", null);
    store.commit("setToken", null);
    store.commit("setTokenStatus", false);
    store.commit("setAuthCode", null);
    store.dispatch("updateToken", null);
    store.commit("setUser", null);
    store.commit("setUserRole", "anonymous");
    store.commit("setCallbackURL", null);
    store.commit("getMasterdata", null);
    if (req === true) {
      Toast.showToast("Signed out successfully!", "S");
    } else {
      Toast.showToast(
        "Your session has expired due to inactivity. Please log in again to continue.",
        "E"
      );
    }
    setTimeout(() => {
      window.location.href = URL;
    }, 3000);
  },
  getAccessToken: function (authCode) {
    return axios.get(`${ROOT_URL}/auth/token/${authCode}`);
  },
  refreshToken: function () {
    return axios.get(`${ROOT_URL}/auth/refreshtoken/`);
  },
  getUserInfo: function () {
    return axios.get(`${ROOT_URL}/auth/userinfo`);
  },
  getOrganization: function (id) {
    return axios.get(`${ROOT_URL}/organizations/${id}`);
  },
  getOrganizationFormOne: function (id, individualId) {
    return axios.get(`${ROOT_URL}/certification/combined-forms`, {
      params: {
        imisNumber: id,
        individualId: individualId,
      },
    });
  },
  getApplicationList: function (req) {
    return axios.get(`${ROOT_URL}/certification/imis/${req?.id}`, {
      params: {
        page: req?.page,
        size: req?.size,
        sort: req?.sort,
      },
    });
  },

  getApplicationDetails: function (id) {
    return axios.get(`${ROOT_URL}/certification/${id}`);
  },
  getDockerFileDetails: function (version) {
    return axios.get(
      `${ROOT_URL}/certification/docker-info?version=${version}`
    );
  },
  submitDPApplicationForm: function (request) {
    return axios.post(`${ROOT_URL}/certification`, request);
  },
  downloadDockerFile: function (imgVersion) {
    return axios.get(
      `${ROOT_URL}/certification/download-docker?imageVersion=${imgVersion}`
    );
  },
  updateApplicationStatus: function (request) {
    return axios.put(`${ROOT_URL}/certification/${request.id}/status`, null, {
      params: {
        status: request.status,
      },
    });
  },
  uploadPackage: function (req) {
    console.log(req);
    let formdata = new FormData();
    formdata.append("package", req.upload.file);
    return axios.post(
      `${ROOT_URL}/certification/${req.upload.id}/upload-package`,
      formdata
    );
  },
  getViewResults: function (id) {
    return axios.get(`${ROOT_URL}/certification/${id}/reports`);
  },
  updatePaymentStatus: function (request) {
    return axios.put(`${ROOT_URL}/certification/${request.id}/status`, null, {
      params: {
        paymentStatus: request.status,
      },
    });
  },
  getApplicationListByFilter: function (req) {
    return axios.get(`${ROOT_URL}/certification`, {
      params: {
        page: req?.page,
        size: req?.size,
        sort: req?.sort,
        status: req?.status,
        paymentStatus: req?.pStatus,
        organizationNames: req?.orgName,
      },
    });
  },
  submitChecklist: function (consentDetails) {
    console.log("Calling submitChecklist API with:", consentDetails);
    return axios.post(`${ROOT_URL}/certification/consent`, consentDetails);
  },
  submitOrganizationDetails: function (organizationDetails) {
    return axios.post(`${ROOT_URL}/certification/form1`, organizationDetails);
  },
  submitPointOfContact: function (pointOfContact) {
    return axios.post(`${ROOT_URL}/certification/form2`, pointOfContact);
  },
  submitApiAndProduct: function (apiAndProduct) {
    return axios.post(`${ROOT_URL}/certification/form3`, apiAndProduct);
  },
  submitCombinedFormsDetails: function (
    consentId,
    form1Id,
    form2Id,
    form3Id,
    imisNumber,
    individualId
  ) {
    return axios.get(`${ROOT_URL}/certification/combined-forms`, {
      params: {
        consentId: consentId,
        form1Id: form1Id,
        form2Id: form2Id,
        form3Id: form3Id,
        imisNumber: imisNumber,
        individualId: individualId,
      },
    });
  },
  submitApplicationDetails: function (
    consentId,
    form1Id,
    form2Id,
    form3Id,
    applicationDetails
  ) {
    return axios.post(`${ROOT_URL}/certification`, applicationDetails, {
      params: {
        consentId: consentId,
        form1Id: form1Id,
        form2Id: form2Id,
        form3Id: form3Id,
      },
    });
  },
  async updateConsentDetails(id, consentDetails) {
    console.log("Calling updateConsentDetails API with:", id, consentDetails);
    return axios.put(
      `${ROOT_URL}/certification/updateConsent/${id}`,
      consentDetails
    );
  },
  async updateOrganizationDetails(id, organizationDetails) {
    return axios.put(
      `${ROOT_URL}/certification/form1/${id}`,
      organizationDetails
    );
  },
  async updatePointOfContactDetails(id, pointOfContact) {
    return axios.put(`${ROOT_URL}/certification/form2/${id}`, pointOfContact);
  },
  async updateApiAndProductionDetails(id, apiAndProduct) {
    return axios.put(`${ROOT_URL}/certification/form3/${id}`, apiAndProduct);
  },
  async softDeleteCertification(consentId, form1Id, form2Id, form3Id) {
    const params = new URLSearchParams({
      consentId,
      form1Id,
      form2Id,
      form3Id,
    });
    return axios.delete(
      `${ROOT_URL}/certification/soft-delete?${params.toString()}`
    );
  },
  getMasterdatadetails: function () {
    return axios.get(`${ROOT_URL}/get/master-data`);
  },
  getLatestForms: function (individualId) {
    return axios.get(`${ROOT_URL}/certification/latest-forms`, {
      params: {
        individualId: individualId,
      },
    });
  },
 
};
